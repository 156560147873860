@import "styles/core.scss";
.link {
  transition: all 0.2s ease-out;
  text-decoration: underline;
  text-underline-offset: var(--space-50);
  color: var(--color-text-secondary);

  &:focus-visible {
    box-shadow: 0 0 0 var(--borders-weight-sm) var(--color-text-secondary);
  }

  @include hover {
    color: var(--color-interactive-primary-hover);
  }
}
