/**
 * A 6-column grid layout to define spacing between elements.
 */
.colLayout {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: var(--space-300);
}

.colLayoutDouble {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: var(--space-300);
}

.col1 {
  grid-column: span 1;
}

.col2 {
  grid-column: span 2;
}

.col3 {
  grid-column: span 3;
}

.col4 {
  grid-column: span 4;
}

.col5 {
  grid-column: span 5;
}

.col6 {
  grid-column: span 6;
}

.col8 {
  grid-column: span 8;
}

@mixin colLayout {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: var(--space-300);
}

@mixin colLayoutDouble {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: var(--space-300);
}

@mixin colCount($count) {
  grid-column: span $count;
}
